//Libraris
import { Link } from 'gatsby-plugin-react-i18next'
import styled, { css } from 'styled-components'

type ButtonProps = {
  disabled?: boolean
}

export const ButtonStyle = css<ButtonProps>`
  align-items: center;
  border: none;
  border-radius: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  justify-content: center;
  outline: none;
  text-decoration: none;
  transition: all ease-in-out 0.3s;
  user-select: none;
  white-space: nowrap;
`

export const LightButton = styled.button`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.white01
    : theme.colors.white00};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.blueDarkerLighter 
    : theme.colors.blueDarkerDefault};
  font-size: ${({ theme }) => theme.fontSize.small};
  min-height: 55px;
  min-width: 136px;
  padding: 16px 24px;
  :hover{
    background-color: ${({ theme }) => theme.colors.white01};
  };
`

export const LightButtonLink = styled(Link)`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.white01
    : theme.colors.white00};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.blueDarkerLighter 
    : theme.colors.blueDarkerDefault};
  font-size: ${({ theme }) => theme.fontSize.small};
  min-height: 55px;
  min-width: 136px;
  width: min-content;
  :hover{
    background-color: ${({ theme }) => theme.colors.white01};
  };
`

export const MainButton = styled.button`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.blueDarkerLighter
    : theme.colors.blueDarkerDefault};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.white01 
    : theme.colors.white00};
  font-size: ${({ theme }) => theme.fontSize.small};
  min-height: 55px;
  min-width: 136px;
  padding: 16px 24px;
  :hover{
    background-color: ${({ theme, disabled }) => 
    disabled 
    ? theme.colors.blueDarkerLighter
    : theme.colors.blueDarkerLight
    };
  }
`

export const MainButtonLink = styled(Link)`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.blueDarkerLighter
    : theme.colors.blueDarkerDefault};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.white01 
    : theme.colors.white00};
  font-size: ${({ theme }) => theme.fontSize.small};
  min-height: 55px;
  min-width: 136px;
  width: min-content;
  :hover{
    background-color: ${({ theme, disabled }) => 
    disabled 
    ? theme.colors.blueDarkerLighter
    : theme.colors.blueDarkerLight
    };
  }
`

export const SecondaryButton = styled.button`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.purpleLighter
    : theme.colors.purpleLight};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.white01 
    : theme.colors.white00};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  min-height: 49px;
  min-width: 109px;
  padding: 16px;
  :hover{
    background-color: ${({ theme, disabled }) => 
    disabled 
    ? theme.colors.purpleLighter
    : theme.colors.purpleDefault
    };
  }
`

export const SecondaryButtonLink = styled(Link)`
  ${ButtonStyle};
  background-color: ${({ theme, disabled}) =>
    disabled 
    ? theme.colors.purpleLighter
    : theme.colors.purpleLight};
  color: ${({ disabled, theme }) =>
    disabled 
    ? theme.colors.white01 
    : theme.colors.white00};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  min-height: 49px;
  min-width: 109px;
  width: min-content;
  :hover{
    background-color: ${({ theme, disabled }) => 
    disabled 
    ? theme.colors.purpleLighter
    : theme.colors.purpleDefault
    };
  }
`
