// React
import { FC } from 'react'

// Libraries
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

// Custom
import {
  ContactUs,
  ContactUsContainer,
  FormContainer,
  Text,
  Title,
  TitlesContainer,
} from './styled'
import { PaddingContainer } from 'components/UI/Containers'
import { CONTACT, SEO } from 'locales'
import Form from 'components/Form'
import SeoElement from 'components/SeoElement'
import WrapProvider from 'WrapProvider'

const Contact: FC = () => {
  const { t } = useTranslation()
  
  return (
    <WrapProvider>
      <SeoElement
        title={SEO.contact.title}
        description={SEO.contact.description}
      />
      <ContactUs>
        <PaddingContainer>
          <ContactUsContainer>
            <TitlesContainer>
              <Title>{t(CONTACT.title)}</Title>
              <Text>{t(CONTACT.subtitle)}</Text>
            </TitlesContainer>
            <FormContainer>
              <Form />
            </FormContainer>
          </ContactUsContainer>
        </PaddingContainer>
      </ContactUs>
    </WrapProvider>
  )
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
