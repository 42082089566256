// Libraries
import styled from 'styled-components'

// Custom
import DeskBackground from 'images/images/contactUSBackgroundDesk.png'
import MobileBackground from 'images/images/contactUSBackgroundMobile.png'

export const ContactUs = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue800};
  background-image: url(${MobileBackground});
  background-repeat: no-repeat;
  background-position: 0px -50px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    background-image: url(${DeskBackground});
  }
`

export const ContactUsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const TitlesContainer = styled.div`
  padding: 81px 0px 48px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 100%;
    align-items: flex-start;
    padding: 0px;
  }
`

export const Title = styled.h1`
  margin: 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.h4};
  color: ${({ theme }) => theme.colors.blueDarkerLight};
  line-height: 33.6px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h1};
  }
`

export const Text = styled.p`
  margin: 16px 0px 0px 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.blueDarkerLight};
  line-height: 21px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    margin: 20px 0px 0px 0px;
    text-align: left;
    max-width: 445px;
    font-size: ${({ theme }) => theme.fontSize.p};
    line-height: 27px;
  }
`

export const FormContainer = styled.div`
  margin-bottom: 146px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 138px 0px 215px 0px;
  }
`
